/*
  ************************ CSS table of content ************************

  1)  reset css
  2)  global css
  3)  header
  4)  hero
  5)  inner-hero-section
  6)  about section
  7)  next-draw-section
  8)  how to play section
  9)  contest section
  10) contest slider section
  11) contest feature section
  12) winner section
  13) winner details section
  14) latest winner section
  15) overview section
  16) features section
  17) team section
  18) testimonial section
  19) support section
  20) counter section
  21) contest-details-section
  22) lottery-details
  23) cart section
  24) checkout section
  25) affiliate single section
  26) how it work section
  27) affiliate partner section
  28) top affiliate section
  29) video section
  30) buy ticket section
  31) client section
  32) faq section
  33) blog section
  34) blog single section
  35) contact section
  36) login
  37) user section
  38) error-section
  39) sidebar
  40) footer section

  **************************************************************** */
// abstracts sass
@import "./abstracts/mixins";
@import "./abstracts/variables";
@import "./abstracts/functions";

// base sass
@import "./base/base";
@import "./base/typography";

// components sass
@import "./components/buttons";
@import "./components/form";
@import "./components/keyframes";

// layout sass
@import "./layout/header";
@import "./layout/hero-section";
@import "./layout/sections";
@import "./layout/sidebar";
@import "./layout/footer";
